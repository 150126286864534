import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import { component } from 'vxe-table'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    // meta: { hidden: true, title: "登录" },
    component: () => import('@/Login.vue'),
    // children: [
    //   {
    //     path: '/login',
    //     component: () => import('@/Login.vue')
    //   }
    // ]
  },
  {
    path: '/Mistake/Upload',
    component: () => import('@/views/Mistake/Upload.vue')
  },
  {
    path: '/Mistake/Query',
    component: () => import('@/views/Mistake/Query.vue')
  },
  {
    path: '/Mistake/Manage',
    component: () => import('@/views/Mistake/Manage.vue')
  },
  {
    path: '/Mistake/Task',
    component: () => import('@/views/Mistake/Task.vue')
  },
  {
    path: '/Mistake/Print',
    component: () => import('@/views/Mistake/Print.vue')
  },
  {
    path: '/StudyTask/Testing',
    component: () => import('@/views/StudyTask/Testing.vue')
  },

  {
    path: '/Training/Upload',
    component: () => import('@/views/Training/Upload.vue')
  },

  {
    path: '/User/ChangeInfo',
    component: () => import('@/views/User/ChangeInfo.vue')
  },


  {
    path: '/redirect',
    // component: Layout,
    // meta: { hidden: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/redirect.vue')
      }
    ]
  },

  {
    path: "/",
    // component: () => import('@/Index.vue'),
    children: [
      {
        path: '/',
        component: () => import('@/Index.vue')
      }
    ]
  }

  // {
  //   path: '/',
  //   name: 'home',
  //   component: LoginView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
