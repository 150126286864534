import axios from "axios";
import { ElNotification, ElMessageBox, ElMessage } from "element-plus";

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    withCredentials: false,
    timeout: 60000
})

export const isRelogin = { show: false }

// 异常拦截处理器
const errorHandler = (error) => {
    const status = error.response.status;
    console.log(status)
    switch (status) {
        /* eslint-disable no-param-reassign */
        case 400: error.message = 'Invalid request'; break;
        case 401: error.message = 'Not authorized, please log in'; location.href = '/login'; break;
        case 403: error.message = 'Access denied'; break;
        case 404: error.message = `Invalid request address: ${error.response.config.url}`; break;
        case 408: error.message = 'Request timeout'; break;
        case 500: error.message = 'Server internal error'; break;
        case 501: error.message = 'Service not implemented'; break;
        case 502: error.message = 'Gateway error'; break;
        case 503: error.message = 'Service unavailable'; break;
        case 504: error.message = 'Gateway timeout'; break;
        case 505: error.message = 'HTTP versions are not supported'; break;
        default: break;
        /* eslint-disabled */
    }
    return Promise.reject(error);
};

service.interceptors.request.use(
    request => {
        request.headers = request.headers || {};

        const tokenKey = "Test_Token";
        const token = sessionStorage.getItem(tokenKey);
        if (token != null) {
            request.headers['Authorization'] = `Bearer ${token}`
        }

        if (request.method === 'get' && request.params) {
            let url = request.url + '?' + request.params;
            url = url.slice(0, -1);
            request.params = {};
            request.url = url;
        }

        if (request.method === 'post' || request.method === 'put') {
            // const requestObj = {
            //     url: request.url,
            //     data: typeof request.data === 'object' ? JSON.stringify(request.data) : request.data,
            //     time: new Date().getTime()
            // }

            // const sessionObj: any = sessionCache.getJSON('sessionObj')
            // if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
            //   sessionCache.setJSON('sessionObj', requestObj)
            // } else {
            //   const s_url = sessionObj.url;                  // 请求地址
            //   const s_data = sessionObj.data;                // 请求数据
            //   const s_time = sessionObj.time;                // 请求时间
            //   const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
            //   if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
            //     const message = 'Data is being processed, please do not submit it repeatedly';
            //     console.warn(`[${s_url}]: ` + message)
            //     return Promise.reject(new Error(message))
            //   } else {
            //     sessionCache.setJSON('sessionObj', requestObj)
            //   }
            // }

            //liao 2024-07-24
            //sessionCache.setJSON('sessionObj', requestObj)
        }

        return request;
    }, errorHandler
)

service.interceptors.response.use(
    response => {
        const code = response.status
        const msg = response.statusText

        if (response.data instanceof Blob) {
            return response
        }

        if (code === 401) {
            if (!isRelogin.show) {
                isRelogin.show = true;
                ElMessageBox.confirm('The login status has expired, you can stay on the page or log in again.', 'System prompt', {
                    confirmButtonText: 'log in again',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    //store.dispatch('LogOut').then(() => {
                    //location.href = '/index';
                    //})
                })
            }
            return Promise.reject('Invalid session, or the session has expired, please log in again.')
        } else if (code === 500) {
            ElMessage({
                message: msg,
                type: 'error'
            })
            return Promise.reject(new Error(msg))
        } else if (code === 601) {
            ElMessage({
                message: msg,
                type: 'warning'
            })
            return Promise.reject('error')
        } else if (code !== 200) {
            ElNotification.error({
                title: msg
            })
            return Promise.reject('error')
        } else {
            //console.log(response.data)
            return response.data
        }
    }, errorHandler
)

export default service
