import { get, post, doGet, clearToken } from "@/utils/jwtApi";

export function getpublickey(success) {
    get("/Encrypt/PublicKey", success);
}

export function doGetpublickey() {
    return doGet("/Encrypt/PublicKey");
}

export function getSaltFromApi(success) {
    get("/Encrypt/Salt", success);
}

export function doGetSaltFromApi() {
    return doGet("/Encrypt/Salt");
}

export function getUserInfoFromApi(success) {
    get("/Student/Get", success);
}

export function getUserRoleFromApi(success) {
    get("/User/Status", success);
}

export function pwdSignIn(postStr: string, success, fail) {
    return post("/Signin/UserPwd", { Value: postStr }, success, fail);
}

const userNameKey = "Test_User";
const userRoleKey = "Test_UserRole";

export function isLogined() {
    const user = getCurrentUserName();
    const role = getCurrentUserRole();
    const loginStatus = user != null && user.length > 0 && role != null && role.length > 0;
    return loginStatus;
}

export function getCurrentUserName() {
    return sessionStorage.getItem(userNameKey);
}

export function getCurrentUserRole() {
    return sessionStorage.getItem(userRoleKey);
}

export function signOut() {
    clearToken();
    sessionStorage.removeItem(userNameKey);
    sessionStorage.removeItem(userRoleKey);
}